import emptyController from './controllers/empty';
import postPageController from './controllers/post-page';
import postListWidgetController from './controllers/post-list-widget';
import archiveListWidgetController from './controllers/archive-list-widget';
import categoryListWidgetController from './controllers/category-list-widget';
import postTickerWidgetController from './controllers/post-ticker-widget';
import {
  ARCHIVE_LIST_WIDGET_TYPE,
  POST_WIDGET_ID_PROD,
  POST_LIST_WIDGET_TYPE,
  CATEGORY_LIST_WIDGET_TYPE,
  POST_TICKER_WIDGET_TYPE,
} from './constants/widgets';

const controllerByType = {
  [POST_TICKER_WIDGET_TYPE]: postTickerWidgetController,
  [CATEGORY_LIST_WIDGET_TYPE]: categoryListWidgetController,
  [ARCHIVE_LIST_WIDGET_TYPE]: archiveListWidgetController,
  [POST_WIDGET_ID_PROD]: postPageController,
  [POST_LIST_WIDGET_TYPE]: postListWidgetController,
};

export const initAppForPage = () => Promise.resolve();

export const createControllers = controllerConfigs =>
  controllerConfigs.map(config => Promise.resolve((controllerByType[config.type] || emptyController)(config)));

export const exports = {};
